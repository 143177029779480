import React from "react";
import Select, { components } from "react-select";
import { Container, CustomSelectDiv, SelectedChip, SelectedChipDiv } from "./elements";
import { useState } from "react";
import { useEffect } from "react";
import DeleteIcon from "../icons/DeleteIcon";
import { H6Medium, H6Strong } from "../Typography";
import GlobalContext from "../GlobalContext";


const MultipleFilter = ({ filterList, onFilter }) => {
  const { primaryColor } = React.useContext(GlobalContext);
  
  const [selectedFilter, setSelectedFilter] = useState(null);

  const selectStyle = {
    menu: (base) => ({
      ...base,
      width: "max-content",
      maxWidth: "350px",
      marginTop: "2px",
    }),
    control: (base, state) => ({
      display: "inline-flex",
      background: state.menuIsOpen ? "var(--dark)" : "transparent",
      border: "none",
      boxShadow: "none",
      padding: "5px 8px 5px 0px",
      transition: "all 0.3s",
      cursor: "var(--cursor-hover) 0 0, pointer",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "12px",
      color: "var(--text-color)",
      fontWeight: 400,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      padding: 0,
      color: "var(--text-color) !important",
      transition: "all .3s ease",
    }),
    menuList: (base) => ({
      ...base,
      background: "var(--dark)",
      color: "var(--text-color)",
      cursor: "var(--cursor-hover) 0 0, pointer",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: "transparent !important",
      cursor: "var(--cursor-hover) 0 0, pointer",
      position: "relative",
      paddingRight: "40px",
      fontSize: "12px",
      color: state.isSelected ? "var(--primary)" : "var(--text-color)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }),
  };

  const showClearAll = selectedFilter?.some((filterType) => filterType?.selectedOptions?.length > 0);

  useEffect(() => {
    setSelectedFilter(
      filterList?.map((filterType) => ({
        id: filterType?.id,
        selectedOptions: [],
      }))
    );
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryString = window.location.search;
      if (queryString) {
        const urlParams = new URLSearchParams(queryString);
        const selectedOptions = {};
        const dateFilterType = urlParams.get("date");
        const timeFilterType = urlParams.get("time");
        const eventFilterType = urlParams.get("event");
        const locationFilterType = urlParams.get("location");

        if (dateFilterType) {
          filterList?.forEach((filterType) => {
            if (filterType?.id === "date") {
              selectedOptions["date"] = filterType?.options?.filter((option) =>
                dateFilterType?.split(",")?.some((date) => {
                  return new Date(date).getTime() === new Date(option?.value).getTime();
                })
              );
            }
          });
        }

        if (eventFilterType) {
          filterList?.forEach((filterType) => {
            if (filterType?.id === "event") {
              selectedOptions["event"] = filterType?.options?.filter((option) =>
                eventFilterType?.toLowerCase()?.includes(option?.label?.toLowerCase())
              );
            }
          });
        }

        if (timeFilterType) {
          filterList?.forEach((filterType) => {
            if (filterType?.id === "time") {
              selectedOptions["time"] = filterType?.options?.filter((option) =>
                timeFilterType?.toLowerCase()?.includes(option?.label?.toLowerCase())
              );
            }
          });
        }

        if (locationFilterType) {
          filterList?.forEach((filterType) => {
            if (filterType?.id === "location") {
              selectedOptions["location"] = filterType?.options?.filter((option) =>
                locationFilterType?.toLowerCase()?.includes(option?.value?.toLowerCase())
              );
            }
          });
        }

        setSelectedFilter((prevFilter) => {
          return prevFilter?.map((filter) => ({ ...filter, selectedOptions: selectedOptions?.[filter?.id] ?? [] }));
        });
      }
    }
  }, []);

  useEffect(() => {
    onFilter(selectedFilter);
  }, [selectedFilter]);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
          <path d="M21 8.9375L10.8437 0.499999L0.6875 8.9375L2.12137 10.5L10.8437 3.20758L19.5661 10.5L21 8.9375Z" fill="white" />
        </svg>
      </components.DropdownIndicator>
    );
  };

  const onFilterChange = (field, values) => {
    setSelectedFilter((prevFilter) => {
      return prevFilter?.map((filter) => (filter?.id === field ? { ...filter, selectedOptions: values } : filter));
    });
  };

  const onClearAllFilter = () => {
    setSelectedFilter((prevFilter) => {
      return prevFilter?.map((filter) => ({ ...filter, selectedOptions: [] }));
    });
  };

  const onDeleteOption = (field, value) => {
    setSelectedFilter((prevFilter) => {
      return prevFilter?.map((filter) =>
        filter?.id === field ? { ...filter, selectedOptions: filter?.selectedOptions?.filter((option) => option?.value !== value) } : filter
      );
    });
  };

  return (
    <Container>
      <CustomSelectDiv primaryColor={primaryColor?.replace("#", "%23")}>
        <H6Strong>Filter by</H6Strong>
        {filterList?.map((filter, i) => {
          return (
            <Select
            menuPosition="fixed"
              key={filter?.id}
              styles={selectStyle}
              isSearchable={false}
              value={selectedFilter?.find((filterType) => filterType?.id === filter?.id)?.selectedOptions ?? []}
              options={filter?.options}
              isMulti
              hideSelectedOptions={false}
              placeholder={filter?.label}
              controlShouldRenderValue={false}
              isClearable={false}
              closeMenuOnSelect={false}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: DropdownIndicator,
              }}
              classNames={{
                option: (state) => (state.isSelected ? "selected" : ""),
                dropdownIndicator: () => "arrowIndicator",
                control: (state) => (state.menuIsOpen ? "menuOpen" : ""),
                menuPortal: () => 'menuWrapper',
                menu: () => 'menu',
              }}
              onChange={(values) => onFilterChange(filter.id, values)}
            />
          );
        })}
      </CustomSelectDiv>
      <SelectedChipDiv>
        {selectedFilter?.map((filterType) => {
          return filterType?.selectedOptions?.map((option) => (
            <SelectedChip key={option?.value}>
              <H6Medium>{option?.label}</H6Medium>
              <div style={{ display: "flex" }} onClick={() => onDeleteOption(filterType?.id, option?.value)}>
                <DeleteIcon />
              </div>
            </SelectedChip>
          ));
        })}
        {showClearAll ? <a onClick={onClearAllFilter}>Clear Selection</a> : null}
      </SelectedChipDiv>
    </Container>
  );
};

export default MultipleFilter;
