import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  color: var(--text-color);

  @media (max-width: 1000px) {
    margin: 0;
    > h3 {
      font-size: 24px;
    }
  }
  > h3 {
    margin-bottom: 20px;
    svg {
      width: 20px;
      height: 10px;
      margin-left: 25px;
    }
  }
`;
