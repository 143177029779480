import React, { memo } from "react";
import { Container } from "./elements";
import EventCardList from "../EventCardList";

const lg_layout = [
  { x: 0, y: 0, w: 5, h: 2 },
  { x: 5, y: 0, w: 3, h: 1 },
  { x: 8, y: 0, w: 4, h: 1 },
  { x: 5, y: 0, w: 7, h: 1 },
  { x: 0, y: 0, w: 4, h: 1 },
  { x: 4, y: 0, w: 4, h: 1 },
  { x: 8, y: 0, w: 4, h: 1 },
  { x: 0, y: 0, w: 5, h: 2 },
  { x: 5, y: 0, w: 3, h: 1 },
  { x: 8, y: 0, w: 4, h: 1 },
  { x: 5, y: 0, w: 7, h: 1 },
  { x: 0, y: 0, w: 3, h: 1 },
  { x: 3, y: 0, w: 3, h: 1 },
  { x: 6, y: 0, w: 3, h: 1 },
  { x: 9, y: 0, w: 3, h: 1 },
];

const md_layout = [
  { x: 0, y: 0, w: 3, h: 1 },
  { x: 3, y: 0, w: 5, h: 1 },
  { x: 0, y: 0, w: 8, h: 1 },
  { x: 0, y: 0, w: 5, h: 1 },
  { x: 5, y: 0, w: 3, h: 1 },
  { x: 0, y: 0, w: 8, h: 1 },
];

const ProgrammeEventList = ({ events }) => {
  return (
    <Container>
      <EventCardList events={events} lg_layout={lg_layout} md_layout={md_layout} rowHeight={295} spacing={20} />
    </Container>
  );
};

export default ProgrammeEventList;
