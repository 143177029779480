import React from "react";
import PropTypes from "prop-types";
import { SmallButtonDiv, BigButtonDiv } from "./elements";

export const SmallButtonSchedule = ({
  children,
  disabled,
  color,
  backgroundColor,
  randomNumber,
  ...props
}) => (
  <SmallButtonDiv
    color={color}
    disabled={disabled}
    backgroundColor={backgroundColor}
    randomNumber={randomNumber}
    {...props}
  >
    {children}
  </SmallButtonDiv>
);

export const BigButtonSchedule = ({
  children,
  disabled,
  color,
  ...props
}) => (
  <BigButtonDiv color={color} disabled={disabled} {...props}>
    {children}
  </BigButtonDiv>
);

SmallButtonSchedule.propTypes = {
  disabled: PropTypes.bool,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

SmallButtonSchedule.defaultProps = {
  disabled: false,
};
