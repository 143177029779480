import React from "react";

const DeleteIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <mask id="mask0_12645_4675" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_12645_4675)">
      <path
        d="M4.26927 12.6667L3.33594 11.7333L7.06927 8L3.33594 4.26667L4.26927 3.33334L8.0026 7.06667L11.7359 3.33334L12.6693 4.26667L8.93594 8L12.6693 11.7333L11.7359 12.6667L8.0026 8.93334L4.26927 12.6667Z"
        fill={color || "#fff"}
      />
    </g>
  </svg>
);

export default DeleteIcon;
