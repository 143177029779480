import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 25px var(--outerMargin) 21px;
  @media (max-width: 767px) {
    padding: 30px 20px 36px;
  }
`;
export const CustomSelectDiv = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--text-color);
  gap: 10px;
  @media (max-width: 767px) {
    h6 {
      width: 100%;
    }
  }
  .menuOpen {
    .arrowIndicator {
      transform: rotate(180deg);
    }
  }
  @media (max-width: 767px) {
    .menuWrapper {
      left: 8px;
      width: 100%;
      .menu {
        max-width: unset;
        width: calc(100% - 20px);
      }
    }
  }
  
  .selected:after {
    content: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='check'%3E%3Cmask id='mask0_12645_4589' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='16' height='17'%3E%3Crect id='Bounding box' y='0.5' width='16' height='16' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_12645_4589)'%3E%3Cpath id='check_2' d='M6.37031 12.5L2.57031 8.69996L3.52031 7.74996L6.37031 10.6L12.487 4.48329L13.437 5.43329L6.37031 12.5Z' fill='${prop => prop.primaryColor ? `${prop.primaryColor}` : "#23EF4653"}'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    height: 14px;
    display: inline-block;
    width: 14px;
    position: absolute;
    top: 38%;
    right: 12px;
    transform: translateY(-50%) scale(1.5);
  }
`;

export const SelectedChipDiv = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 15px;
  a {
    color: var(--mid);
    text-decoration: underline;
    font-size: 12px;
    padding: 0;
    line-height: 34px;
    letter-spacing: 0.03em;
    font-weight: normal;
    white-space: nowrap;
    user-select: none;
    margin-left: 8px;
    transition: 0.5s;
    &:hover {
      color: var(--primary);
    }
  }
`;

export const SelectedChip = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 5px 12px;
  color: var(--text-color);
  background: var(--dark);
  svg {
    cursor: var(--cursor-hover) 0 0, pointer;
  }
`;
